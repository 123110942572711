export const SUPPORTED_LANG = ['en', 'fr'];

export let LANG = localStorage.getItem('lang');

export const setLang = (lang: string) => {
	LANG = lang;
	localStorage.setItem('lang', lang);
	return lang;
}

const initLang = () => {
	console.log(SUPPORTED_LANG)
	return setLang(navigator.languages.map(lang => lang.split('-')[0])
		.find(lang => SUPPORTED_LANG.includes(lang)) ?? SUPPORTED_LANG[0]);
}

if (LANG == null) initLang();

const appLocalizations: { [key in string]: { [key: string]: string } } = {
	en: {
		homepage_headline: 'Capture, Share, Decide: Your Style, Their Say',
		homepage_step1: 'Take a photo of what you want an opinion on',
		homepage_step2: 'Share your thoughts and receive votes in return',
		homepage_step3: 'See results after a few minutes',
		privacy_policy: 'Privacy Policy',
		terms_of_use: 'Terms of Use',
		contact_us: 'Contact Us',
		email_placeholder: 'Email',
		message_placeholder: 'Type your message here...',
		submit: 'Submit',
		send_success: 'Message sent',
		send_failure: 'Failed to send message',
	},
	fr: {
		homepage_headline: 'Capture, Partage, Décide: Votre style, leur avis',
		homepage_step1: 'Prends une photo de ce sur quoi tu veux un avis',
		homepage_step2: 'Partage ton avis et reçois des votes en retour',
		homepage_step3: 'Consulte les résultats après quelques minutes',
		privacy_policy: 'Politique de confidentialité',
		terms_of_use: "Conditions d'utilisation",
		contact_us: 'Contact',
		email_placeholder: 'E-mail',
		message_placeholder: 'Tape ton message ici...',
		submit: 'Envoyer',
		send_success: 'Message envoyé',
		send_failure: "Erreur lors de l'envoi",
	},
}

export const getTranslation = (key: string) => appLocalizations[LANG ?? 'en'][key] ?? '';
