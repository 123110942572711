import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ContactUs, HomePage } from './screens';
import PrivacyPolicyMarkdown from './markdown/privacy-policy.md';
import TermsOfUseMarkdown from './markdown/terms-of-use.md';
import MarkdownPage from './markdown/MarkdownPage';

const root = ReactDOM.createRoot(
  	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<HomePage />}/>
				<Route path="/privacy-policy" element={<MarkdownPage filePath={PrivacyPolicyMarkdown} />}/>
				<Route path="/terms-of-use" element={<MarkdownPage filePath={TermsOfUseMarkdown} />}/>
				<Route path="/contact-us" element={<ContactUs />}/>
				<Route path="*" element={<Navigate to="/" replace />}/>
			</Routes>
		</BrowserRouter>
	</React.StrictMode>
);
