import iosLink from '../images/ios_link.svg';
//import androidLink from '../images/android_link.png';
import Layout from '../components/Layout';
import { getTranslation } from '../l10n/l10n';

const HomePage: React.FC = () => {
	return (
		<Layout id='homepage'>
			<span>{getTranslation('homepage_headline')}</span>
			<ol>
				<li>{getTranslation('homepage_step1')}</li>
				<li>{getTranslation('homepage_step2')}</li>
				<li>{getTranslation('homepage_step3')}</li>
			</ol>
			<div id='download-links'>
				<a href="https://apps.apple.com/us/app/wassupmirror/id6541761906">
					<img src={iosLink} alt='iOS download button'/>
				</a>
				{/*
				<a href="https://play.google.com/store/apps/details?id=wassupmirror">
					<img src={androidLink} alt='android download button'/>
				</a>
				*/}
			</div>
		</Layout>
	);
}

export default HomePage;
