import React from "react";
import { ExtraProps } from "react-markdown";

const HeadingRenderer: React.FC<ExtraProps> = ({ node }) => {
	if (node?.children[0].type === 'text') {
		const text = node?.children[0].value;
		const anchor = text.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, '').replace(/ /g, '-');
		return <a id={anchor} href={`#${anchor}`} style={{ textDecoration: 'none', color: 'black' }}>
			{React.createElement(node?.tagName, null, text)}
		</a>;
	}
	return <></>;
};

export default HeadingRenderer;
