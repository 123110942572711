import Markdown from 'react-markdown';
import Layout from '../components/Layout';
import { useEffect, useState } from 'react';
import HeadingRenderer from './HeadingRenderer';
import remarkGfm from 'remark-gfm';

interface MarkdownPageProps {
	filePath: string;
}

const MarkdownPage: React.FC<MarkdownPageProps> = props => {
	const [markdown, setMarkdown] = useState('');

	useEffect(() => {
		fetch(props.filePath)
			.then(response => response.text())
			.then(setMarkdown);
	}, [props.filePath]);

	return (
		<Layout id='markdown'>
			<Markdown
				remarkPlugins={[remarkGfm]}
				components={{
					h2: props => <HeadingRenderer {...props} />,
					h3: props => <HeadingRenderer {...props} />,
					h4: props => <HeadingRenderer {...props} />,
					h5: props => <HeadingRenderer {...props} />,
					h6: props => <HeadingRenderer {...props} />,
				}}
			>
				{markdown}
			</Markdown>
		</Layout>
	);
}

export default MarkdownPage;
