import logo from '../images/logo.png';
import frame0 from '../images/Frame-0.jpg';
import frame1 from '../images/Frame-1.jpg';
import frame2 from '../images/Frame-2.jpg';
import frame3 from '../images/Frame-3.jpg';
import frame4 from '../images/Frame-4.jpg';
import frame5 from '../images/Frame-5.jpg';
import frame6 from '../images/Frame-6.jpg';
import frame7 from '../images/Frame-7.jpg';
import frame8 from '../images/Frame-8.jpg';
import { LANG, SUPPORTED_LANG, getTranslation, setLang } from '../l10n/l10n';

const shuffle = <T,>(arr: T[]) => arr.sort(() => Math.random() - 0.5);

const note_frames = shuffle([frame0, frame1, frame2]);
const many_frames = shuffle([frame3, frame4, frame5]);
const home_frames = shuffle([frame6, frame7, frame8]);
const frames = shuffle([
	[note_frames[0], many_frames[0], home_frames[0]],
	[home_frames[1], note_frames[1], many_frames[1]],
	[many_frames[2], home_frames[2], note_frames[2]],
]);

interface LayoutProps {
	children?: React.ReactNode;
	id?: string;
}

const Layout: React.FC<LayoutProps> = props => {
  return (
    <div id={`${props.id}-root`}>
		<div id='left-pane'>
			<div id='header'>
				<a href='/'>
					<img id='logo' src={logo} alt='logo'/>
				</a>
				<select value={LANG!} onChange={e => {
					setLang(e.target.value);
					window.location.reload();
				}}>
					{SUPPORTED_LANG.map(lang => <option 
						key={lang}
						value={lang}
					>
						{lang.toUpperCase()}
					</option>)}
				</select>
			</div>
			<div id='content' className={props.id}>
				{props.children}
			</div>
			<div id='footer'>
				<a href="/privacy-policy">{getTranslation('privacy_policy')}</a>
				<a href="/terms-of-use">{getTranslation('terms_of_use')}</a>
				<a href="/contact-us">{getTranslation('contact_us')}</a>
			</div>
		</div>
		<div id='right-pane'>
			{
				frames.map((column, i) => <div key={`column-${i}`} className='column'>
					{
						column.map((frame, j) => <img key={`column-${i}-${j}`} className='mockup' src={frame} alt='app screenhot examples'/>)
					}
				</div>)
			}
		</div>
    </div>
  );
}

export default Layout;
