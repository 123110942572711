import { ChangeEventHandler, FormEventHandler, useMemo, useState } from 'react';
import Layout from '../components/Layout';
import { useLocation } from 'react-router-dom';
import { getTranslation } from '../l10n/l10n';
import axios from 'axios';

const SEND_EMAIL_URL = 'https://drfs57as8h.execute-api.eu-west-3.amazonaws.com/prod/email';

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const ContactUs: React.FC = () => {
	const query = useQuery();
	const [formData, setFormData] = useState({
		email: query.get('email') ?? '',
		message: query.get('message') ?? ''
	});
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState<boolean>();

	const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
		const { name, value } = e.target;
		setFormData(prevState => ({
			...prevState,
			[name]: value
		}));
	};

	const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
		e.preventDefault();
		if (loading) return;
		setLoading(true);
		axios.post(SEND_EMAIL_URL, formData).then(() => {
			setSuccess(true);
			setFormData({
				email: query.get('email') ?? '',
				message: ''
			});
		}).catch(() => setSuccess(false))
		.finally(() => setLoading(false));
	};

	return (
		<Layout id='contact-us'>
			<form onSubmit={handleSubmit}>
				{query.get('email') == null && <input
					type='email'
					id='email'
					name='email'
					placeholder={getTranslation('email_placeholder')}
					value={formData.email}
					onChange={handleChange}
					required
				/>}
				<textarea
					id='message'
					name='message'
					placeholder={getTranslation('message_placeholder')}
					value={formData.message}
					onChange={handleChange}
					required
				/>
				<button type="submit">
					{loading ? <span className='spinner'/> : getTranslation('submit')}
				</button>
				{success !== undefined && !loading && <span style={{ 
					textAlign: 'center',
					color: success ? 'green' : 'red'
				}}>
					{success ? `🚀 ${getTranslation('send_success')}` : `🚧 ${getTranslation('send_failure')}`}
				</span>}
			</form>
		</Layout>
	);
}

export default ContactUs;
